@import url(/style/css/common.css);

.logo[data-v-6ccfe811] {
  height: 15vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo img[data-v-6ccfe811] {
  height: 100%;
}
.info[data-v-6ccfe811] {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #666;
  letter-spacing: 1px;
  line-height: 2;
  text-indent: 26px;
}
.box[data-v-6ccfe811] {
  display: flex;
  flex-direction: column;
  background: #fff;
}
.box-body[data-v-6ccfe811] {
  padding: 20px;
}
.box .box-header[data-v-6ccfe811] {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  padding: 0px 20px;
  box-sizing: border-box;
}
.box .box-header .title[data-v-6ccfe811] {
  font-size: 16px;
  font-weight: 700;
  color: #212831;
}


/*# sourceMappingURL=chunk-2f04b65a.f35369ae.css.map*/